import React, { FC } from 'react';
import { FileField } from 'src/interfaces/common';
import classes from './index.module.scss';

interface PDFViewerProps {
  file?: FileField | null;
  showToolbar?: boolean;
}

const hideToolBat = '#toolbar=0';

export const ServerSharedPDFViewer: FC<PDFViewerProps> = ({ file, showToolbar = true }) => {
  if (!file) {
    return null;
  }
  const toolBar = showToolbar ? '' : hideToolBat;
  return (
    <div>
      <iframe
        className={classes['styled-iframe']}
        title="pdf document"
        id="print-file"
        src={(file.publicFileUrl || '') + toolBar}
      />
    </div>
  );
};
