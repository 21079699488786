import { getCookie } from 'cookies-next';
import mixpanel, { Dict } from 'mixpanel-browser';
import pj from 'package.json';
import { COGNITO_JWT } from 'src/constants/loggedIn';
import { useGetMeQuery } from 'src/store/user/api';

export enum mixpanelEvents {
  userFeedback = 'User feedback',
  userLogin = 'Login',
  userRegistration = 'Registration',
  userViewPage = 'View Page',
  founderPersonalData = 'Founder - Personal Data Submitted',
  investorPersonalData = 'Investor - Personal Data Submitted',
  managerPersonalData = 'Investment Manager - Personal Data Submitted',
  founderPersonalDataIdentification = 'Founder - Personal Identification Submitted',
  investorPersonalDataIdentification = 'Investor - Personal Identification Submitted',
  managerPersonalDataIdentification = 'Investment Manager - Personal Identification Submitted',
  beneficialOwnerIdentification = 'Investor - Beneficial Owner Partner Identification Submitted',
  beneficialOwnerIdentificationCompletion = 'Investor - Beneficial Owner Partner Identification Completion',
  founderVentureSectionSubmitted = 'Founder - Venture Selection Submitted',
  founderVentureSelectionCompletion = 'Founder - Venture Selection Completion',
  investorSPVSubmitted = 'Investor - SPV Identification Submitted',
  investorSPVIdentificationCompletion = 'Investor - SPV Identification Completion',
  investorAccreditation = 'Investor - Investor Accreditation Submitted',
  investorAccreditationCompletion = 'Investor - Investor Accreditation Completion',
  investorWalletSetupCompletion = 'Investor - Wallet Setup Completion',
  managerAccreditation = 'Investment Manager - Role Accreditation Submitted',
  managerAccreditationCompletion = 'Investment Manager - Role Accreditation Completion',
  fullProfileCompletion = 'Full Profile Completion',
  waitListSignUpStarted = 'Waitlist Sign-up Started',
  waitlistInitialSignUp = 'Waitlist Initial Sign-up',
  waitListProfileCompletion = 'Waitlist Profile Completion',
  contactSupport = 'Contact Support',
  createUser = 'Create new user',
  resetDemo = 'Reset Demo',
}

export const useMixpanel = () => {
  // we can place some logic here to predefined some mixpanel behavior
  // official docs for events - https://developer.mixpanel.com/docs/javascript-full-api-reference
  const isLoggedIn = getCookie(COGNITO_JWT);
  const { data: user = null } = useGetMeQuery(null, { skip: !isLoggedIn });

  const trackUserFeedBack = (data: Dict) => {
    mixpanel.track(mixpanelEvents.userFeedback, data);
  };

  const track = (event: mixpanelEvents, data: Dict) => {
    const distinctId = user ? { $distinct_id: user.userId.toString() } : {};
    const appVersion = pj.version;
    mixpanel.track(event, { ...data, ...distinctId, ['app-version']: appVersion });
  };

  const mixpanelLogin = (id: string) => {
    mixpanel.identify(id);
  };

  const mixpanelLogout = () => {
    mixpanel.reset();
  };

  return {
    mixpanel,
    trackUserFeedBack,
    track,
    mixpanelLogin,
    mixpanelLogout,
  };
};
