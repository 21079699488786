import * as PAGES from 'src/constants/paths';
import { Roles, User } from 'src/interfaces/user';
import { getSingleUserRole } from 'src/queries/users/get-user.query';
import { AppNavigationConfig, RolesForNavigation, PathItem } from 'src/interfaces/app-navigation';

const commonPages = {
  home: { path: PAGES.HOME_PAGE, breadcrumb: { title: 'aquaty-app.home.title' } },
  menu: { path: PAGES.MENU_PAGE, breadcrumb: { title: 'aquaty-app.menu.title' } },
};

const menuNotifications = {
  path: PAGES.MENU_NOTIFICATION_PAGE,
  breadcrumb: { title: 'aquaty-generic.menu.notifications.title' },
};

const releaseEquityOffering = {
  path: PAGES.RELEASE_EQUITY_OFFERING_PAGE,
  breadcrumb: { title: 'aquaty-app.aq-staff.administration.release-equity-offering' },
};

const homeArchive = {
  path: PAGES.OFFERINGS_ARCHIVE_PAGE,
  breadcrumb: { title: 'aquaty-app.venture-pitch.cta.archive' },
};

const portfolioPages = {
  portfolio: { path: PAGES.PORTFOLIO_PAGE, breadcrumb: { title: 'aquaty-app.portfolio.title' } },
  portfolioDetail: {
    path: PAGES.PORTFOLIO_DETAIL_PAGE,
    breadcrumb: { title: '', isDynamic: true, query: ['assetId'] },
  },
  portfolioUpdates: {
    path: PAGES.PORTFOLIO_UPDATES_PAGE,
    breadcrumb: { title: 'aquaty-app.venture.updates.title' },
  },
  portfolioVenturesUpdates: {
    path: PAGES.PORTFOLIO_VENTURES_UPDATES_PAGE,
    breadcrumb: { title: 'aquaty-app.venture.updates.title' },
  },
};

const dealRoomPart = {
  equityOfferings: {
    // shadow page (not exists in app yet)
    path: PAGES.OFFERINGS_PAGE,
    breadcrumb: { title: 'aquaty-app.home.title' },
  },
  dealRoom: {
    path: PAGES.DEAL_ROOM_PAGE,
    breadcrumb: { title: '', isDynamic: true, query: ['offeringId'] },
    headerTag: {
      type: 'private',
      show: false,
      text: 'aquaty-app.equity-actions.equity-offering.set-up-equity-offering.status.private.title',
      club: null,
    },
  },
};

const portfolioInvestmentOffering = {
  path: PAGES.PORTFOLIO_INVESTMENT_OFFERING_PAGE,
  breadcrumb: { title: '', isDynamic: true },
};

const dealRoomRoleAssignment = {
  path: PAGES.ROLE_ASSIGNMENT_PAGE,
  breadcrumb: { title: 'aquaty-app.equity-offering.role-assignment.title' },
};

const spvIssuanceAgreementSigning = {
  path: PAGES.SPV_ISSUANCE_AGREEMENT_SIGNING,
  breadcrumb: {
    title:
      'aquaty-app.equity-actions.equity-offering.state.equity-offering-release.tasks.spv-issuance-signing.title',
  },
};

const confirmMinimumFundingAmountCommitted = {
  path: PAGES.CONFIRM_FINDING_AMOUNT_PAGE,
  breadcrumb: { title: 'aquaty-app.aq-staff.confirm-minimum-funding-amount-committed' },
};

const paymentConfirmation = {
  path: PAGES.PAYMENT_CONFIRMATION_PAGE,
  breadcrumb: { title: 'aquaty-app.aq-staff.administration.investment-commitment-payment' },
};

const capitalTransfer = {
  path: PAGES.CAPITAL_TRANSFER_PAGE,
  breadcrumb: { title: 'aquaty-app.aq-staff.money-transfer.title' },
};

// app sidebar components start ----------------------------------------
const menuCommonItemForSidebar = {
  path: commonPages.menu.path,
  title: 'aquaty-app.menu.title',
  iconSet: 'menu' as const,
};
// app sidebar components end ----------------------------------------

const roleNavigationConfig = new Map<RolesForNavigation, AppNavigationConfig>([
  [
    Roles.INVESTOR,
    {
      sidebar: [
        {
          path: PAGES.HOME_PAGE,
          title: 'aquaty-app.portfolio.title',
          iconSet: 'portfolio',
          nestedPaths: [
            PAGES.PORTFOLIO_PAGE,
            PAGES.PORTFOLIO_DETAIL_PAGE,
            PAGES.PORTFOLIO_UPDATES_PAGE,
            PAGES.PORTFOLIO_VENTURES_UPDATES_PAGE,
            PAGES.PORTFOLIO_INVESTMENT_OFFERING_PAGE,
          ],
        },
        {
          path: PAGES.OFFERINGS_PAGE,
          iconSet: 'offering',
          title: 'aquaty-app.investment-opportunities.title',
          nestedPaths: [
            PAGES.DEAL_ROOM_PAGE,
            PAGES.SIGN_INVESTMENT_PAGE,
            PAGES.DATA_ROOM_REVIEW_PAGE,
            PAGES.OFFERINGS_PAGE,
            PAGES.OFFERINGS_ARCHIVE_PAGE,
          ],
        },
        {
          ...menuCommonItemForSidebar,
          nestedPaths: [
            PAGES.MENU_NOTIFICATION_PAGE,
            PAGES.MENU_ADD_HOLDING_PAGE,
            PAGES.MENU_WALLET_SETUP_PAGE,
          ],
        },
      ],
      paths: {
        equityOfferings: {
          ...dealRoomPart.equityOfferings,
          breadcrumb: { title: 'aquaty-app.investment-opportunities.title' },
        },
        signInvestment: {
          path: PAGES.SIGN_INVESTMENT_PAGE,
          breadcrumb: { title: 'aquaty-app.equity-offerings.investment-committment.title' },
        },
        dataRoomReview: {
          path: PAGES.DATA_ROOM_REVIEW_PAGE,
          breadcrumb: { title: 'aquaty-app.equity-offering.data-room-review.title' },
        },
        ...portfolioPages,
        dealRoom: dealRoomPart.dealRoom,
        menuAddHolding: {
          path: PAGES.MENU_ADD_HOLDING_PAGE,
          breadcrumb: { title: 'aquaty-app.menu.add-holding.title' },
        },
        menuNotifications,
        menu: commonPages.menu,
        home: {
          path: PAGES.HOME_PAGE,
          breadcrumb: { title: 'aquaty-app.investment-opportunities.title' },
        },
        homeArchive,
        walletSetup: {
          path: PAGES.MENU_WALLET_SETUP_PAGE,
          breadcrumb: { title: 'aquaty-app.profile-completion.wallet-setup.title' },
        },
        portfolioInvestmentOffering,
      },
    },
  ],
  [
    Roles.AQUATY_STAFF,
    {
      sidebar: [
        {
          path: PAGES.USERS_PAGE,
          iconSet: 'accreditation',
          title: 'aquaty-app.aq-staff.users.title',
          nestedPaths: [PAGES.ADMIN_BENEFICIAL_OWNER_VERIFICATION_PAGE],
        },
        {
          path: PAGES.OFFERINGS_PAGE,
          iconSet: 'offering',
          title: 'aquaty-app.investment-offerings.title',
          nestedPaths: [
            PAGES.RELEASE_EQUITY_OFFERING_PAGE,
            PAGES.DATA_ROOM_REVIEW_PAGE,
            PAGES.PAYMENT_CONFIRMATION_PAGE,
            PAGES.CONFIRM_FINDING_AMOUNT_PAGE,
            PAGES.CAPITAL_TRANSFER_PAGE,
            PAGES.DEAL_ROOM_PAGE,
            PAGES.SPV_ISSUANCE_AGREEMENT_SIGNING,
            PAGES.REGULATORY_APPROVAL_PAGE,
          ],
        },
        {
          path: PAGES.ISSUERS_PAGE,
          iconSet: 'issuer',
          title: 'aquaty-app.issuers.title',
          nestedPaths: [PAGES.ISSUER_SPV_VERIFICATION_PAGE],
        },
        {
          ...menuCommonItemForSidebar,
          nestedPaths: [PAGES.MENU_PLATFORM_CONFIGURATION_PAGE],
        },
      ],
      paths: {
        menu: commonPages.menu,
        ...dealRoomPart,
        users: {
          path: PAGES.USERS_PAGE,
          breadcrumb: { title: 'aquaty-app.aq-staff.users.title' },
        },
        equityOfferings: {
          path: PAGES.OFFERINGS_PAGE,
          breadcrumb: { title: 'aquaty-app.investment-offerings.title' },
        },
        equityOfferingsDetails: {
          path: PAGES.DEAL_ROOM_PAGE,
          breadcrumb: { title: '', isDynamic: true, query: ['offeringId'] },
          isNotLink: true,
        },
        dataRoomReview: {
          path: PAGES.DATA_ROOM_REVIEW_PAGE,
          breadcrumb: { title: 'aquaty-app.equity-offering.data-room-review.title' },
        },
        capitalTransfer,
        paymentConfirmation,
        confirmMinimumFundingAmountCommitted,
        issuersPage: {
          path: PAGES.ISSUERS_PAGE,
          breadcrumb: { title: 'aquaty-app.issuers.title' },
        },
        issuerDetails: {
          path: PAGES.ISSUER_DETAILS_PAGE,
          breadcrumb: { title: '', isDynamic: true },
        },
        issuerSPVVerification: {
          path: PAGES.ISSUER_SPV_VERIFICATION_PAGE,
          breadcrumb: { title: 'aquaty-app.issuer-securitization-vehicles-verification.title' },
        },
        userAccreditationDetails: {
          path: PAGES.USER_DETAILS_PAGE,
          breadcrumb: { title: '', isDynamic: true },
        },
        spvIssuanceAgreementSigning,
        regulatoryApproval: {
          path: PAGES.REGULATORY_APPROVAL_PAGE,
          breadcrumb: { title: 'aquaty-app.aq-staff.regulatory-approval.title' },
        },
        beneficialOwnerVerification: {
          path: PAGES.ADMIN_BENEFICIAL_OWNER_VERIFICATION_PAGE,
          breadcrumb: { title: 'aquaty-app.beneficial-owner-self-selection-verification.title' },
        },
        platformConfiguration: {
          path: PAGES.MENU_PLATFORM_CONFIGURATION_PAGE,
          breadcrumb: { title: 'aquaty-generic.menu.platform-configuration.title' },
        },
      },
    },
  ],
  [
    Roles.INVESTMENT_MANAGER,
    {
      sidebar: [
        {
          path: PAGES.HOME_PAGE,
          iconSet: 'home',
          title: 'aquaty-app.investment-offerings.title',
          nestedPaths: [
            PAGES.DEAL_ROOM_PAGE,
            PAGES.ROLE_ASSIGNMENT_PAGE,
            PAGES.DEAL_TEAM_SETUP_PAGE,
            PAGES.RELEASE_EQUITY_OFFERING_PAGE,
            PAGES.SPV_ISSUANCE_AGREEMENT_SIGNING,
            PAGES.CONFIRM_FINDING_AMOUNT_PAGE,
            PAGES.PAYMENT_CONFIRMATION_PAGE,
            PAGES.CAPITAL_TRANSFER_PAGE,
            PAGES.ASSET_INVESTMENT_CLOSING_PAGE,
            PAGES.TERM_SHEET_REVIEW_PAGE,
            PAGES.RELEASE_TO_ALL_INVESTORS_PAGE,
            PAGES.INVESTMENT_MEMORANDUM_SETUP_PAGE,
            PAGES.MANAGER_VENTURE_PITCH_SETUP_PAGE,
            PAGES.MANAGER_DATA_ROOM_SETUP_PAGE,
            PAGES.MANAGER_INVESTMENT_OFFERING_SETUP_PAGE,
            PAGES.CLOSE_EQUITY_OFFERING_PAGE,
            PAGES.OFFERINGS_PAGE,
            PAGES.ISSUER_SETUP_PAGE,
            PAGES.OFFERINGS_ARCHIVE_PAGE,
            PAGES.OFFERINGS_COMPLETED_PAGE,
          ],
        },
        {
          path: PAGES.PORTFOLIO_PAGE,
          iconSet: 'portfolio',
          title: 'aquaty-app.portfolio.title',
          nestedPaths: [
            PAGES.PORTFOLIO_DETAIL_PAGE,
            PAGES.PORTFOLIO_UPDATES_PAGE,
            PAGES.PORTFOLIO_VENTURES_UPDATES_PAGE,
            PAGES.PORTFOLIO_INVESTMENT_OFFERING_PAGE,
          ],
        },
        {
          path: PAGES.INVESTOR_CRM_PAGE,
          iconSet: 'issuer',
          title: 'aquaty-app.investor-crm.title',
          nestedPaths: [],
        },
        {
          ...menuCommonItemForSidebar,
          nestedPaths: [PAGES.MENU_NOTIFICATION_PAGE],
        },
      ],
      paths: {
        home: {
          ...commonPages.home,
          breadcrumb: { title: 'aquaty-app.investment-offerings.title' },
        },
        homeArchive,
        homeCompleted: {
          path: PAGES.OFFERINGS_COMPLETED_PAGE,
          breadcrumb: { title: 'aquaty-generic.cta.completed' },
        },
        menu: commonPages.menu,
        equityOfferings: {
          ...dealRoomPart.equityOfferings,
          breadcrumb: { title: 'aquaty-app.investment-offerings.title' },
        },
        issuerSetup: {
          path: PAGES.ISSUER_SETUP_PAGE,
          breadcrumb: { title: 'aquaty-app.issuer-setup.title' },
        },
        dealRoom: dealRoomPart.dealRoom,
        portfolio: {
          ...portfolioPages.portfolio,
          breadcrumb: { title: 'aquaty-app.managed-portfolio.title' },
        },
        portfolioDetail: {
          ...portfolioPages.portfolioDetail,
        },
        portfolioUpdates: portfolioPages.portfolioUpdates,
        portfolioVenturesUpdates: portfolioPages.portfolioVenturesUpdates,
        dealRoomRoleAssignment,
        menuNotifications,
        investorCRM: {
          path: PAGES.INVESTOR_CRM_PAGE,
          breadcrumb: { title: 'aquaty-app.investor-crm.title' },
        },
        spvIssuanceAgreementSigning,
        releaseEquityOffering,
        confirmMinimumFundingAmountCommitted,
        paymentConfirmation,
        capitalTransfer,
        assetInvestmentClosing: {
          path: PAGES.ASSET_INVESTMENT_CLOSING_PAGE,
          breadcrumb: { title: 'aquaty-app.asset-investment-closing.title' },
        },
        termSheetReview: {
          path: PAGES.TERM_SHEET_REVIEW_PAGE,
          breadcrumb: { title: 'aquaty-app.term-sheet-review.title' },
        },
        releaseToAllInvestors: {
          path: PAGES.RELEASE_TO_ALL_INVESTORS_PAGE,
          breadcrumb: { title: 'aquaty-app.release-to-all-aquaty-investors.title' },
        },
        capTable: {
          path: PAGES.MANAGER_CAP_TABLE_SETUP_PAGE,
          breadcrumb: {
            title: 'aquaty-app.equity-actions.equity-offering.legal-venture-data.title',
          },
        },
        venturePitch: {
          path: PAGES.MANAGER_VENTURE_PITCH_SETUP_PAGE,
          breadcrumb: { title: 'aquaty-app.venture-pitch' },
        },
        setUpDataRoom: {
          path: PAGES.MANAGER_DATA_ROOM_SETUP_PAGE,
          breadcrumb: { title: 'aquaty-app.equity-actions.equity-offering.data-room.title' },
        },
        investmentOfferingSetup: {
          path: PAGES.MANAGER_INVESTMENT_OFFERING_SETUP_PAGE,
          breadcrumb: { title: 'aquaty-app.investment-offering-setup.title' },
        },
        investmentMemorandumSetup: {
          path: PAGES.INVESTMENT_MEMORANDUM_SETUP_PAGE,
          breadcrumb: { title: 'aquaty-app.investment-memorandum-setup.title' },
        },
        dealRoomCloseEquityOffering: {
          path: PAGES.CLOSE_EQUITY_OFFERING_PAGE,
          breadcrumb: { title: 'aquaty-generic.cta.close-equity-offering' },
        },
        portfolioInvestmentOffering,
      },
    },
  ],
]);

export const getPathItemByPathname = (pathname: string): PathItem => {
  let item;
  for (const { paths } of roleNavigationConfig.values()) {
    item = Object.keys(paths)
      .map((el) => paths[el])
      .find((el) => el.path === pathname);

    if (item) break;
  }
  return item as PathItem;
};

export const getConfigByUser = (user: User, currentRole?: Roles): AppNavigationConfig => {
  const role =
    (currentRole as RolesForNavigation) || (getSingleUserRole(user) as RolesForNavigation);

  return {
    sidebar: roleNavigationConfig.get(role)?.sidebar || [],
    paths: roleNavigationConfig.get(role)?.paths || {},
  };
};

export const getStaffPaths = (): AppNavigationConfig['paths'] => {
  return roleNavigationConfig.get(Roles.AQUATY_STAFF)?.paths || {};
};

export const getFounderPaths = (): AppNavigationConfig['paths'] => {
  return roleNavigationConfig.get(Roles.VENTURE_FOUNDER)?.paths || {};
};

export const getInvestorPaths = (): AppNavigationConfig['paths'] => {
  return roleNavigationConfig.get(Roles.INVESTOR)?.paths || {};
};

export const getInvestmentManagerPaths = (): AppNavigationConfig['paths'] => {
  return roleNavigationConfig.get(Roles.INVESTMENT_MANAGER)?.paths || {};
};
