/* eslint-disable indent */
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';
import { getCookie } from 'cookies-next';

import { findLocalItems } from './localStorageActions';
import { logOutFunction } from 'src/hooks/useLogout';
import { isDemo } from '../_middleware';
import { DEMO_SIGN_IN_PAGE, NOT_AUTHORIZED_PAGES, SIGN_IN_PAGE } from 'src/constants/paths';
import { USER_ID } from 'src/constants/registration';

const defaultConfig: AxiosRequestConfig = {
  baseURL: process.env.API_BASE,
  timeout: 2 * 60 * 1000,
  headers: {
    'content-type': 'application/json',
  },
};

export const publicHeaders = {
  headers: {
    'content-type': 'application/json',
    'x-api-key': process.env.X_API_KEY || '',
  },
};

export const instance = axios.create(defaultConfig);

instance.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (config: any) => {
    // if (config.headers.Authorization === false) {
    //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //   const { Authorization, ...rest } = config.headers;
    //   return {
    //     ...config,
    //     headers: { ...rest },
    //   };
    // }
    if (config.headers?.Authorization) return config;

    const token = findLocalItems();
    const authorization = token ? { Authorization: `Bearer ${token}` } : {};
    return {
      ...config,
      headers: { ...config.headers, ...authorization },
    };
  },
  (error) => Promise.reject(error),
);

export const http = async <T>({
  url,
  method = 'GET',
  params,
  data,
  ...rest
}: AxiosRequestConfig): Promise<AxiosResponse<T>> =>
  instance({ url, method, params, data, ...rest });

export const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, unknown, unknown> =>
  async ({ url, method, data, params, ...rest }) => {
    try {
      const result = await instance({ url, method, data, params, ...rest });
      return { data: result.data };
    } catch (err) {
      console.log('err', err);
      const logout = () => {
        logOutFunction();
        window.location.replace(
          isDemo ? DEMO_SIGN_IN_PAGE : SIGN_IN_PAGE + `?redirectPage=${window.location.pathname}`,
        );
      };
      const loggedUserId = getCookie(USER_ID);
      const refreshToken = findLocalItems('refreshToken');
      const pathname = window.location.pathname;
      const isAxiosError = axios.isAxiosError(err) && err.response?.status === 401;

      if (refreshToken && loggedUserId && isAxiosError) {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = cognitoUser.signInUserSession;
        cognitoUser.refreshSession(currentSession.refreshToken, (error: any) => {
          if (error) {
            logout();
          }
        });
        const result = await instance({ url, method, data, params, ...rest });
        return { data: result.data };
      }

      if (isAxiosError && !NOT_AUTHORIZED_PAGES.includes(pathname)) {
        logout();
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data,
          },
        };
      }
      if (axios.isAxiosError(err)) {
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data,
          },
        };
      }

      throw new Error('Unknown error');
    }
  };
