import { useTranslation } from 'next-i18next';

import Logout24Px from 'public/svg-components/Logout24Px';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { useLogout } from 'src/hooks/useLogout';
import { User } from 'src/interfaces/user';
import { ServerSharedAQImageV2 } from 'src/server-shared/aq-image';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';

interface SideBarUserMenuProps {
  user: User | null;
  onHide: () => void;
}

export const SidebarUserMenu = ({ user, onHide }: SideBarUserMenuProps) => {
  const { t } = useTranslation();
  const { logout } = useLogout();

  return (
    <div className={classes['user-menu']} onPointerLeave={onHide}>
      <div className={classes['user-menu__menu']}>
        <div className={classes['user-menu__block']}>
          <div className="grid-row none">
            <div
              className={classNames(
                classes['user-menu__item'],
                classes['user-menu__item--soft-disabled'],
              )}
            >
              <ServerSharedAQImageV2
                image={user?.photo}
                width={24}
                height={24}
                borderRadius="round"
              >
                {!user?.photo && (
                  <ServerSharedText>
                    {(user?.firstName || '').charAt(0).toUpperCase()}
                  </ServerSharedText>
                )}
              </ServerSharedAQImageV2>

              <ServerSharedText color="body" ellipsis>
                {user?.email}
              </ServerSharedText>
            </div>
          </div>
        </div>

        <div className={classes['user-menu__footer']}>
          <div onClick={logout} className={classes['user-menu__item']}>
            <Logout24Px className={classes['stroke-style']} />
            <ServerSharedText color="body">{t('aquaty-generic.cta.logout')}</ServerSharedText>
          </div>
        </div>
      </div>
    </div>
  );
};
