'use client';

import { cloneElement, isValidElement } from 'react';
import { useTranslation } from 'next-i18next';

import { ServerSharedButton } from 'src/server-shared/buttons/button';
import { ModalFooterProps } from 'src/server-shared/modals/modal/types';
import { spacing } from 'src/styles/spacing';
import { classNames } from 'src/utils/classNames';
import classes from '../index.module.scss';

export const ModalFooter = ({ children, closeEvent, ...rest }: ModalFooterProps) => {
  const { t } = useTranslation();
  const {
    leftBtnText = 'aquaty-generic.cta.cancel',
    hasLeftButton = false,
    buttonText = 'aquaty-generic.cta.create',
    buttonVariant = 'inform',
    onConfirm,
    disabledRightButton,
    footerSize = 'medium',
    withBorder = true,
    buttonLabel,
  } = rest;

  if (children) {
    return (
      <div
        role="modal-footer"
        data-border={withBorder ? ' ' : 'none'}
        className={classNames(classes['modal-footer'], {
          [classes[`modal-footer__${footerSize}`]]: true,
          [classes['modal-footer--border']]: withBorder,
        })}
      >
        {isValidElement(children) ? cloneElement(children, rest) : children}
      </div>
    );
  }

  return (
    <div
      role="modal-footer"
      data-border={withBorder ? ' ' : 'none'}
      className={classNames(classes['modal-footer'], {
        [classes[`modal-footer__${footerSize}`]]: true,
        [classes['modal-footer--border']]: withBorder,
      })}
    >
      {hasLeftButton && (
        <ServerSharedButton
          style={{
            width: footerSize === 'large' ? (hasLeftButton ? '50%' : '100%') : 'auto',
            marginRight: spacing.s,
          }}
          variant="secondary"
          onClick={closeEvent}
          label={t(leftBtnText)}
        />
      )}
      <ServerSharedButton
        style={{ width: footerSize === 'large' ? (hasLeftButton ? '50%' : '100%') : 'auto' }}
        onClick={onConfirm}
        variant={buttonVariant}
        disabled={disabledRightButton}
        label={buttonLabel || t(buttonText)}
      />
    </div>
  );
};
