import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractQuestionnaireType } from 'src/interfaces/user-contract';
import { useGetContractsQuery } from 'src/store/contract/api';
import { GeneralModalProps, ServerSharedModal } from '../modal';
import { ServerSharedPDFViewer } from 'src/server-shared/pdf-viewer';
import { userContractsLabels } from 'src/constants/user-contracts';
import { CheckBoxSetValue, ServerSharedCheckBox } from 'src/server-shared/inputs/checkbox';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { MANAGER_TERMS } from 'src/ui/auth/signup/nested-pages/registration/steps/venture-role';
import { RoleAssignment, Roles, User } from 'src/interfaces/user';
import { useUpdateUserMutation } from 'src/store/user/api';
import { useErrorHandler } from 'src/hooks/useErrorHandler';

interface InvestmentManagerTermsConditionsModalProps extends GeneralModalProps {
  user: User;
  onConfirm: () => void;
}

export const InvestmentManagerTermsConditionsModal = ({
  show,
  user,
  onConfirm,
  closeEvent,
}: InvestmentManagerTermsConditionsModalProps) => {
  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();
  const [checkbox, setCheckbox] = useState<CheckBoxSetValue>([]);
  const [updateUser, { isLoading: saveUserLoading }] = useUpdateUserMutation();
  const { data: userContracts = [] } = useGetContractsQuery(
    `types=${ContractQuestionnaireType.INVESTMENT_MANAGER_TERMS_AND_CONDITIONS}`,
  );
  const chosenContract = userContracts[0];

  const onSubmit = useCallback(async () => {
    await updateUser({
      id: user.userId,
      value: {
        ...user,
        userRoles: [...user.userRoles, { role: Roles.INVESTMENT_MANAGER } as RoleAssignment],
      },
    }).then((data) => {
      errorHandler(data, () => {
        closeEvent();
        onConfirm();
      });
    });
  }, [closeEvent, errorHandler, onConfirm, updateUser, user]);

  return (
    <ServerSharedModal
      show={show}
      size="large"
      closeEvent={closeEvent}
      header={{
        title: t(
          userContractsLabels[ContractQuestionnaireType.INVESTMENT_MANAGER_TERMS_AND_CONDITIONS],
        ),
      }}
      footer={{
        onConfirm: onSubmit,
        hasLeftButton: true,
        disabledRightButton: !checkbox.length || saveUserLoading,
        buttonText: 'aquaty-generic.cta.complete',
      }}
    >
      {chosenContract && (
        <div className="grid-row">
          <ServerSharedPDFViewer file={chosenContract.file} />
          <ServerSharedCheckBox
            label={
              <>
                <ServerSharedText>
                  {t('aquaty-generic.cta.investment-manager-contract.checkbox')}
                </ServerSharedText>{' '}
                <ServerSharedText>
                  {t(
                    userContractsLabels[
                      ContractQuestionnaireType.INVESTMENT_MANAGER_TERMS_AND_CONDITIONS
                    ],
                  )}
                </ServerSharedText>
                {'.'}
              </>
            }
            values={checkbox}
            value={MANAGER_TERMS}
            isChecked={checkbox.includes(MANAGER_TERMS)}
            onCheck={setCheckbox}
            background="primary600"
          />
        </div>
      )}
    </ServerSharedModal>
  );
};
