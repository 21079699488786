/* eslint-disable indent */
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/utils/axios';

import { UserContract } from 'src/interfaces/user-contract';

export const contractApi = createApi({
  reducerPath: 'contractApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Contract'],
  endpoints: (builder) => ({
    getContracts: builder.query<UserContract[], string>({
      query: (query) => ({
        url: `/contracts?${query}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result
          ? result.map(({ customerContractInstanceId }) => ({
              type: 'Contract',
              id: customerContractInstanceId,
            }))
          : [],
    }),

    getAquatyContracts: builder.query<UserContract[], null>({
      query: () => ({
        url: '/contracts/aquaty',
      }),
      providesTags: (result) =>
        result
          ? result.map(({ customerContractInstanceId }) => ({
              type: 'Contract',
              id: customerContractInstanceId,
            }))
          : [],
    }),
  }),
});

export const { useGetContractsQuery, useGetAquatyContractsQuery } = contractApi;
