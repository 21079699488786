import {
  CONGRATULATIONS_PAGE,
  PROFILE_COMPLETION_PAGE,
  REGISTRATION_PAGE,
} from 'src/constants/paths';
import { isDemo } from 'src/_middleware';
import { Roles, User } from 'src/interfaces/user';
import { getSingleUserRole } from 'src/store/user/api';
import { accreditationIsPassed } from 'src/ui/profile-completion/minimized/investor';

interface Instructions {
  skipAllRedirects: boolean;
}

export const checkFinishedRegister = (
  pathname: string,
  user?: User,
): string | Instructions | null => {
  const role = user ? getSingleUserRole(user) : undefined;
  const isInvestor = user?.userRoles.some((el) => el.role === Roles.INVESTOR);
  const hasIMRole = user?.userRoles.some((el) => el.role === Roles.INVESTMENT_MANAGER);

  if (
    pathname.includes(CONGRATULATIONS_PAGE) ||
    pathname.includes(PROFILE_COMPLETION_PAGE) ||
    pathname.includes(REGISTRATION_PAGE) ||
    hasIMRole
  ) {
    return { skipAllRedirects: true };
  }

  if (!user || !role || !user.firstName) {
    return REGISTRATION_PAGE;
  }

  if (isInvestor) {
    if (isDemo) return { skipAllRedirects: true };

    if (!accreditationIsPassed(user)) {
      return PROFILE_COMPLETION_PAGE;
    }

    return { skipAllRedirects: true };
  }

  return null;
};
