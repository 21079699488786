/* eslint-disable indent */
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/utils/axios';

import {
  Asset,
  AssetCapitalisation,
  AssetCustodyDetails,
  AssetForAPI,
  AssetUpdate,
  AssetUpdateForAPI,
} from 'src/interfaces/asset';

export const assetApi = createApi({
  reducerPath: 'assetApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Asset', 'Capitalisation', 'Security-Tokens', 'Asset-Update'],
  endpoints: (builder) => ({
    getAssets: builder.query<Asset[], null | string>({
      query: (query) => ({
        url: `/assets?${query}`,
      }),
      providesTags: (result) =>
        result
          ? result.map(({ assetId }) => ({
              type: 'Asset',
              id: assetId,
            }))
          : [],
    }),

    getAssetById: builder.query<Asset, number | string>({
      query: (assetId) => ({
        url: `/assets/${assetId}`,
      }),
      providesTags: (result) =>
        result
          ? [
              {
                type: 'Asset',
                id: result.assetId,
              },
            ]
          : [],
    }),

    saveAsset: builder.mutation<Asset, Partial<Asset>>({
      query: (data) => ({
        url: '/assets',
        method: 'POST',
        data,
      }),
      invalidatesTags: (result) => [{ type: 'Asset', id: result?.assetId }],
    }),

    updateAsset: builder.mutation<Asset, Partial<AssetForAPI>>({
      query: (data) => ({
        url: `/assets/${data.assetId}`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted({ assetId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedAsset } = await queryFulfilled;
          dispatch(
            assetApi.util.updateQueryData('getAssetById', Number(assetId), (draft) => {
              Object.assign(draft, updatedAsset);
            }),
          );
        } catch (err) {
          console.log('err', err);
        }
      },
    }),

    getAssetCapitalisation: builder.query<AssetCapitalisation[], string>({
      query: (query) => ({
        url: `/assets/capitalisation/current?${query}`,
      }),
      providesTags: (result) =>
        result
          ? result.map(({ assetId }) => ({
              type: 'Capitalisation',
              id: assetId,
            }))
          : [],
    }),

    getAssetSecurityToken: builder.query<AssetCustodyDetails[], string | number>({
      query: (assetId) => ({
        url: `/assets/${assetId}/security-tokens`,
      }),
      providesTags: (result) =>
        result
          ? result.map(({ id }) => ({
              type: 'Security-Tokens',
              id: id,
            }))
          : [],
    }),

    getAssetUpdate: builder.query<AssetUpdate[], string>({
      query: (query) => ({
        url: `/assets/updates?${query}`,
      }),
      providesTags: (result) =>
        result
          ? result.map(({ assetUpdateId }) => ({
              type: 'Asset-Update',
              id: assetUpdateId,
            }))
          : [],
      keepUnusedDataFor: 0,
      transformResponse: (data: AssetUpdate[]) => {
        return [...data].sort((a, b) => ((a.updateDate || '') > (b.updateDate || '') ? -1 : 1));
      },
    }),

    saveAssetUpdate: builder.mutation<
      AssetUpdate,
      { data: Partial<AssetUpdateForAPI>; query: string }
    >({
      query: ({ data }) => ({
        url: `/assets/${data.assetId}/updates`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: update } = await queryFulfilled;
          dispatch(
            assetApi.util.updateQueryData('getAssetUpdate', arg.query, (draft) =>
              [...draft, update].sort((a, b) =>
                (a.updateDate || '') > (b.updateDate || '') ? -1 : 1,
              ),
            ),
          );
        } catch (err) {
          console.log('err', err);
        }
      },
      invalidatesTags: ['Capitalisation'],
    }),

    updateAssetUpdate: builder.mutation<
      AssetUpdate,
      { data: Partial<AssetUpdateForAPI>; query: string }
    >({
      query: ({ data }) => ({
        url: `/assets/${data.assetId}/updates/${data.assetUpdateId}`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedIssuer } = await queryFulfilled;
          dispatch(
            assetApi.util.updateQueryData('getAssetUpdate', arg.query, (draft) =>
              [...draft].map((el) =>
                el.assetUpdateId === updatedIssuer.assetUpdateId ? updatedIssuer : el,
              ),
            ),
          );
        } catch (err) {
          console.log('err', err);
        }
      },
      invalidatesTags: ['Capitalisation'],
    }),

    deleteAssetUpdate: builder.mutation<
      AssetUpdate,
      { assetId: number; assetUpdateId: number; query: string }
    >({
      query: ({ assetId, assetUpdateId }) => ({
        url: `/assets/${assetId}/updates/${assetUpdateId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            assetApi.util.updateQueryData('getAssetUpdate', arg.query, (draft) =>
              [...draft].filter((el) => el.assetUpdateId !== arg.assetUpdateId),
            ),
          );
        } catch (err) {
          console.log('err', err);
        }
      },
      invalidatesTags: ['Capitalisation'],
    }),
  }),
});

export const {
  useGetAssetsQuery,
  useGetAssetByIdQuery,
  useSaveAssetMutation,
  useUpdateAssetMutation,
  useGetAssetCapitalisationQuery,
  useGetAssetUpdateQuery,
  useSaveAssetUpdateMutation,
  useUpdateAssetUpdateMutation,
  useDeleteAssetUpdateMutation,
  useGetAssetSecurityTokenQuery,
} = assetApi;
