/* eslint-disable indent */
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/utils/axios';
import { PortfolioData } from 'src/interfaces/portfolio';
import { addNotification } from '../app/reducer';

export const portfolioApi = createApi({
  reducerPath: 'portfolioApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Unrealised-Portfolio', 'Managed-Portfolio'],
  endpoints: (builder) => ({
    getUnrealizedPortfolioHoldings: builder.query<PortfolioData, null>({
      query: () => ({
        url: '/portfolio/unrealised',
      }),
      providesTags: (result) =>
        result
          ? result.holdings.map(({ assetId }) => ({
              type: 'Unrealised-Portfolio',
              id: assetId,
            }))
          : [],
    }),

    getManagedPortfolioHoldings: builder.query<PortfolioData, null>({
      query: () => ({
        url: '/portfolio/managed',
      }),
      providesTags: (result) =>
        result
          ? result.holdings.map(({ assetId }) => ({
              type: 'Managed-Portfolio',
              id: assetId,
            }))
          : [],
    }),

    updatePortfolio: builder.mutation<PortfolioData, null>({
      query: () => ({
        method: 'POST',
        url: '/portfolio/update',
      }),
      async onQueryStarted(data, { queryFulfilled, dispatch }) {
        try {
          const { data: portfolioData } = await queryFulfilled;
          dispatch(
            portfolioApi.util.updateQueryData('getUnrealizedPortfolioHoldings', null, (draft) => {
              Object.assign(draft, portfolioData);
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          dispatch(
            addNotification({
              title: 'aquaty-app.notifications.error',
              message: err.error.data,
              type: 'error',
            }),
          );
        }
      },
    }),
  }),
});

export const {
  useGetUnrealizedPortfolioHoldingsQuery,
  useGetManagedPortfolioHoldingsQuery,
  useUpdatePortfolioMutation,
} = portfolioApi;
