import { getCookie } from 'cookies-next';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { COGNITO_JWT } from 'src/constants/loggedIn';
import { useGetMeQuery } from 'src/store/user/api';
import { useIsomorphicLayoutEffect } from 'src/hooks/useIsomorphicLayoutEffect';
import { getHubspotToken } from 'src/utils/getHubspotToken';

declare global {
  interface Window {
    hsConversationsSettings: any;
    HubSpotConversations: any;
    safari: any;
  }
}

export const HubspotChat = () => {
  const isLoggedIn = getCookie(COGNITO_JWT);
  const { data: user } = useGetMeQuery(null, {
    skip: !['prod', 'staging'].includes(process.env.RUNTIME_ENVIRONMENT || '') || !isLoggedIn,
  });
  const [token, setToken] = useState<undefined | string>();

  useEffect(() => {
    window.hsConversationsSettings = {
      loadImmediately: false,
    };
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (user && ['prod', 'staging'].includes(process.env.RUNTIME_ENVIRONMENT || '')) {
      const getToken = async () => {
        const token = await getHubspotToken({
          email: user.email || '',
          firstName: user.firstName || '',
          lastName: user.lastName || '',
        });

        window.hsConversationsSettings = {
          identificationEmail: user.email,
          identificationToken: token,
        };
        setToken(token);
      };
      getToken();
    }
  }, [user]);

  if (!user || !token) {
    return null;
  }

  return (
    <>
      <Script id="show-banner">
        {`function onConversationsAPIReady() {
            window.HubSpotConversations.widget.load();
          }

          if (window.HubSpotConversations) {
            onConversationsAPIReady();
          } else {
          window.hsConversationsOnReady = [onConversationsAPIReady];
          }
        `}
      </Script>
      <Script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js-eu1.hs-scripts.com/25843101.js"
        // strategy="beforeInteractive"
      />
    </>
  );
};
