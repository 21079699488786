import { addNotification } from 'src/store/app/reducer';
import { useDispatch } from 'react-redux';

export const useErrorHandler = () => {
  const dispatch = useDispatch();

  const errorHandler = (data: any, callback: () => void) => {
    if ('error' in data && data.error?.data) {
      dispatch(
        addNotification({
          title: 'aquaty-app.notifications.error',
          message: data.error.data,
          type: 'error',
        }),
      );
      return;
    }
    callback();
  };
  return {
    errorHandler,
  };
};
