export const emailRegEx =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const passwordRegEx = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*-]{8,}/;

export const isValidHttpUrl = (string: string) => {
  try {
    // const protocol = 'https://';
    // const isProtocolIncluded = ['https://', 'http://'].some((protocol) =>
    //   string.includes(protocol),
    // );
    return !!new URL(string);
  } catch (_) {
    return false;
  }
};
export const hexRegEx = /^#[0-9A-F]{6}$/i;

// TODO when ditto well be connect we can replace strings with ids
export const validations = {
  emailValidation: (v: string): string =>
    emailRegEx.test(v) ? '' : 'aquaty-app.validation.email-is-not-valid',
  passwordValidation: (v: string): string =>
    passwordRegEx.test(v) ? '' : 'aquaty-app.register.password.symbol-error',
  matchValues: (property: string, errorString: string) => (v: string, rest?: Record<string, any>) =>
    v === rest?.[property] ? '' : errorString,
  required: (v: any): string => (v ? '' : 'aquaty-app.validation.field-is-required'),
  arrayLength: (v: any): string => (v.length > 0 ? '' : 'aquaty-app.validation.field-is-required'),
  radioButton: (v: { id: number }): string =>
    Object.keys(v).length ? '' : 'aquaty-app.validation.field-is-required',
  booleanValidation: (v: boolean): string =>
    typeof v === 'boolean' ? '' : 'aquaty-app.validation.value-not-boolean',
  isValidHttpUrl: (string: string) => {
    if (!string || isValidHttpUrl(string)) return '';

    return 'aquaty-app.validation.link-is-not-valid';
  },
  hexColorValidation: (v: string): string =>
    hexRegEx.test(v) ? '' : 'aquaty-app.validation.invalid-color-code',
};
